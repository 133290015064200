/** @jsxImportSource theme-ui */
import { FC, useMemo } from 'react'
import { Flex } from 'theme-ui'
import _ from 'lodash'
import { Masonry } from 'src/components/layouts'
import {
  PrismicCulturePageDataType,
  PrismicImageType,
} from 'root/graphql-types'
import { xs, ml } from 'src/gatsby-plugin-theme-ui/utils'
import { H2, BodyLarge10Cols, NavigationFit } from 'src/components/atoms/text'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { ResponsiveAsset } from 'src/components/organisms'
import { ImageProp } from 'src/utils/gatsby'
import ArrowSmall from 'src/svg/arrow_small.svg'
import { motion } from 'framer-motion'
import {
  STAGGER_TIME,
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  OFFSET_CARDS_Y,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'

type CulturePageProps = {
  pageContext: PrismicCulturePageDataType & {
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
  }
  path: string
}

const CulturePage: FC<CulturePageProps> = ({ pageContext, path }) => {
  const introTextHtml = pageContext?.intro_text?.html || ''

  const introTextJsx = useMemo(
    () => replaceHtml(introTextHtml, 'p', BodyLarge10Cols),
    [introTextHtml]
  )

  const itemsJsx = useMemo(
    () =>
      _.map(pageContext?.items, (item, key) => {
        const link = item?.link?.url || ''
        const imageDesktop = item?.culture_image_desktop as ImageProp
        const imageMobile = item?.culture_image_mobile as ImageProp

        return (
          <motion.div
            {...yOpacityProps({
              delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * (1 + key),
              initialY: OFFSET_CARDS_Y,
            })}
            key={key}
          >
            <a
              href={link}
              target="blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                display: 'block',
                mb: ml('l', 'xxl'),
              }}
            >
              <ResponsiveAsset
                imageDesktop={imageDesktop}
                imageMobile={imageMobile}
              />
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: ml('s', 'm'),
                }}
              >
                <NavigationFit>{item?.link_title || ''}</NavigationFit>
                <ArrowSmall />
              </Flex>
            </a>
          </motion.div>
        )
      }),
    [pageContext?.items]
  )

  setActiveLink('culture')

  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H2 sx={{ mt: ml('s', 'l'), mb: ml('m', 'l') }}>
          {pageContext?.title}
        </H2>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <div sx={{ mb: ml('xl', 'xxl') }}>{introTextJsx}</div>
      </motion.div>
      <Masonry itemsJsx={itemsJsx} numCols={xs(1, 3)} />
    </div>
  )
}

export default CulturePage
