/** @jsxImportSource theme-ui */
import { FC } from 'react'
import ReactMasonry from 'react-masonry-css'
import {
  gridGapsArray,
  gridGapsArrayMinus
} from 'src/gatsby-plugin-theme-ui/sizes'

type NumCols = [number, number, number, number, number]

type MasonryProps = {
  itemsJsx: any[]
  numCols: NumCols
}

const getBreakpointCols = (numCols: NumCols) => {
  return {
    default: numCols[4], // >  1919
    1919: numCols[3], //    <= 1919
    1279: numCols[2], //    <= 1279
    991: numCols[1], //     <= 991
    767: numCols[0] //      <= 767
  }
}

const Masonry: FC<MasonryProps> = ({ itemsJsx, numCols }) => {
  return (
    <div
      sx={{
        '.masonry-grid': {
          display: 'flex',
          marginLeft: gridGapsArrayMinus /* gutter size offset */,
          width: 'auto'
        },
        '.masonry-grid_column': {
          paddingLeft: gridGapsArray /* gutter size */,
          backgroundClip: 'padding-box'
        },
        '.masonry-grid_column > *': {
          display: 'block'
        }
      }}
    >
      <ReactMasonry
        breakpointCols={getBreakpointCols(numCols)}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {itemsJsx}
      </ReactMasonry>
    </div>
  )
}

export default Masonry
